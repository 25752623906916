export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('loading', {
    created (el, binding) {
     if (binding.value) {
       el.classList.add("state-loading");
     } else {
       el.classList.remove("state-loading")
     }
    },
    updated(el, binding) {
      if (binding.value) {
        el.classList.add("state-loading");
      } else {
        el.classList.remove("state-loading")
      }
    }
  })
})