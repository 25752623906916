<script setup>
const props = defineProps({
  error: Object
})

const getMessage = (code) => {
  switch (code) {
    case 404: return "Page not found!";
    case 429: return "Too many requests!";
    default: return "Ooops... Something went wrong!";
  }
}
</script>

<template>
    <NuxtLayout>
      <div class="container py-8 h-full">
        <div class="flex-col w-full h-full items-center justify-center text-center">
          <div class="text-primary text-4xl">{{ error.statusCode }}</div>
          <div class="text-3xl">{{ getMessage(error.statusCode) }}</div>
        </div>
      </div>
    </NuxtLayout>
</template>

<style scoped>

</style>