export default defineAppConfig({
  oneLink: {
    baseUrl: 'https://novelslink.onelink.me/jRvz/web',
    fakeBooksUrl: 'https://novelslink.onelink.me/jRvz/8a5hg5k5',
    redditUrl: 'https://novelslink.onelink.me/jRvz/reddit'
  },
  store: {
    apple: 'https://apps.apple.com/us/app/fictionme-stories-novels/id1630170714',
    google: 'https://play.google.com/store/apps/details?id=com.fiction.me',
  },
  socials: {
    facebook: 'https://www.facebook.com/fictionmeapp',
    instagram: 'https://www.instagram.com/fictionme.app/',
    tiktok: 'https://www.tiktok.com/@fictionme_novels',
    twitter: 'https://twitter.com/FictionMeApp',
    linkedin: 'https://www.linkedin.com/company/fictionme/',
    youtube: null,
    reddit: null,
    quora: null,
    medium: null,
  },
  authorCabinetUrl: 'https://fictionme.net/author'
})