import { v4 as uuidv4 } from "uuid";

export const useDevice = () => {
    const platformName = ref('');
    const platformVersion = ref('');
    const appVersion = ref('');
    const timezone = ref('');
    const udid = ref('');

    const fetchDeviceData = () => {
        const { browserName, browserVersion } = getBrowserInfo();
        const config = useRuntimeConfig()

        platformName.value = browserName;
        platformVersion.value = browserVersion;
        appVersion.value = config.public.appVersion;
        timezone.value = Intl.DateTimeFormat().resolvedOptions().timeZone;
        udid.value = getOrCreateUDID();
    };

    const getOrCreateUDID = () => {
        let udid = localStorage.getItem('udid');
        if (!udid) {
            udid = uuidv4();
            localStorage.setItem('udid', udid);
        }

        return udid;
    };

    if (process.client) {
        fetchDeviceData();
    }

    return {
        platformName,
        platformVersion,
        appVersion,
        timezone,
        udid,
    };
}

const getBrowserInfo = (): { browserName: string, browserVersion: string } => {
    const userAgent: string = navigator.userAgent;
    let browserName: string = 'Unknown';
    let browserVersion: string = 'Unknown';

    if (userAgent.match(/firefox|fxios/i)) {
        browserName = 'Firefox';
        const match = userAgent.match(/firefox\/(\d+)/i);
        if (match) {
            browserVersion = match[1];
        }
    } else if (userAgent.match(/edg/i)) {
        browserName = 'Edge';
        const match = userAgent.match(/edg\/(\d+)/i);
        if (match) {
            browserVersion = match[1];
        }
    } else if (userAgent.match(/opr\//i)) {
        browserName = 'Opera';
        const match = userAgent.match(/opr\/(\d+)/i);
        if (match) {
            browserVersion = match[1];
        }
    } else if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = 'Chrome';
        const match = userAgent.match(/chrome\/(\d+)/i);
        if (match) {
            browserVersion = match[1];
        }
    } else if (userAgent.match(/safari/i)) {
        browserName = 'Safari';
        const match = userAgent.match(/version\/(\d+)/i);
        if (match) {
            browserVersion = match[1];
        }
    } else if (userAgent.match(/trident/i)) {
        browserName = 'Internet Explorer';
        const match = userAgent.match(/rv:(\d+)/i);
        if (match) {
            browserVersion = match[1];
        }
    }

    return { browserName, browserVersion };
};