import { useAuth } from "~/composible/useAuth";

export default defineNuxtPlugin((nuxtApp) => {
    const { authToken, refreshTheToken }  = useAuth();
    const config = useRuntimeConfig();

    const apiFetch = $fetch.create({
        baseURL: '/web-api',
        headers: {
            'Accept': 'application/json',
        },
        retry: 1,
        retryStatusCodes: [401],
        onRequest({ request, options, error }) {
            if (authToken.value) {
                const headers = options.headers ||= {}
                if (Array.isArray(headers)) {
                    headers.push(['Authorization', `Bearer ${authToken.value}`])
                } else if (headers instanceof Headers) {
                    headers.set('Authorization', `Bearer ${authToken.value}`)
                } else {
                    headers.Authorization = `Bearer ${authToken.value}`
                }
            }
        },
        onResponse: async ({ response, options, request }) => {
            if (response.status === 401) {
                try {
                    const tokens = await refreshTheToken();
                    options.headers = tokens?.access_token ? { Authorization: `${tokens.access_token}` } : {};
                } catch (error) {
                    options.retry = false;
                    console.error('Token refresh failed:', error);

                    reloadNuxtApp();
                }
            }
        },
    })

    return {
        provide: {
            apiFetch
        }
    }
})