import { default as _91slug_93HlQWCk44f7Meta } from "/var/www/fictionme-web/html/pages/blog/[slug].vue?macro=true";
import { default as indexudaTkUsDvXMeta } from "/var/www/fictionme-web/html/pages/blog/index.vue?macro=true";
import { default as _91slug_93Z9R2NC6B7dMeta } from "/var/www/fictionme-web/html/pages/book-reviews/[slug].vue?macro=true";
import { default as indexLsWuMCJZPmMeta } from "/var/www/fictionme-web/html/pages/book-reviews/index.vue?macro=true";
import { default as _91slug_93Ne0AFhA1pMMeta } from "/var/www/fictionme-web/html/pages/book/[slug].vue?macro=true";
import { default as chapter_45_91order_93zEDEowoSxRMeta } from "/var/www/fictionme-web/html/pages/books/[slug]/chapter-[order].vue?macro=true";
import { default as indexzLEcSVk5vLMeta } from "/var/www/fictionme-web/html/pages/books/[slug]/index.vue?macro=true";
import { default as _91slug_93_45novelsKctGfZtnIGMeta } from "/var/www/fictionme-web/html/pages/genres/[slug]-novels.vue?macro=true";
import { default as all_45novelsxF8pV6aIh2Meta } from "/var/www/fictionme-web/html/pages/genres/all-novels.vue?macro=true";
import { default as indexmdWrAeuQ5HMeta } from "/var/www/fictionme-web/html/pages/index.vue?macro=true";
import { default as libraryKTXVH4A0uTMeta } from "/var/www/fictionme-web/html/pages/library.vue?macro=true";
import { default as balanceGo957fJZyGMeta } from "/var/www/fictionme-web/html/pages/profile/balance.vue?macro=true";
import { default as indexxzx7yLa2zDMeta } from "/var/www/fictionme-web/html/pages/profile/index.vue?macro=true";
import { default as settingsasNSgw6uULMeta } from "/var/www/fictionme-web/html/pages/profile/settings.vue?macro=true";
import { default as searchCiBkPTJmzKMeta } from "/var/www/fictionme-web/html/pages/search.vue?macro=true";
import { default as _91slug_93_45novelsbL6SB4Yhs7Meta } from "/var/www/fictionme-web/html/pages/stories/[slug]-novels.vue?macro=true";
import { default as indexzTRUV9E5ErMeta } from "/var/www/fictionme-web/html/pages/stories/index.vue?macro=true";
export default [
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    component: () => import("/var/www/fictionme-web/html/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/var/www/fictionme-web/html/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "book-reviews-slug",
    path: "/book-reviews/:slug()",
    component: () => import("/var/www/fictionme-web/html/pages/book-reviews/[slug].vue").then(m => m.default || m)
  },
  {
    name: "book-reviews",
    path: "/book-reviews",
    component: () => import("/var/www/fictionme-web/html/pages/book-reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "book-slug",
    path: "/book/:slug()",
    component: () => import("/var/www/fictionme-web/html/pages/book/[slug].vue").then(m => m.default || m)
  },
  {
    name: "books-slug-chapter-order",
    path: "/books/:slug()/chapter-:order()",
    component: () => import("/var/www/fictionme-web/html/pages/books/[slug]/chapter-[order].vue").then(m => m.default || m)
  },
  {
    name: "books-slug",
    path: "/books/:slug()",
    component: () => import("/var/www/fictionme-web/html/pages/books/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "genres-slug-novels",
    path: "/genres/:slug()-novels",
    component: () => import("/var/www/fictionme-web/html/pages/genres/[slug]-novels.vue").then(m => m.default || m)
  },
  {
    name: "genres-all-novels",
    path: "/genres/all-novels",
    component: () => import("/var/www/fictionme-web/html/pages/genres/all-novels.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/fictionme-web/html/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "library",
    path: "/library",
    meta: libraryKTXVH4A0uTMeta || {},
    component: () => import("/var/www/fictionme-web/html/pages/library.vue").then(m => m.default || m)
  },
  {
    name: "profile-balance",
    path: "/profile/balance",
    meta: balanceGo957fJZyGMeta || {},
    component: () => import("/var/www/fictionme-web/html/pages/profile/balance.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexxzx7yLa2zDMeta || {},
    component: () => import("/var/www/fictionme-web/html/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-settings",
    path: "/profile/settings",
    meta: settingsasNSgw6uULMeta || {},
    component: () => import("/var/www/fictionme-web/html/pages/profile/settings.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/var/www/fictionme-web/html/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "stories-slug-novels",
    path: "/stories/:slug()-novels",
    component: () => import("/var/www/fictionme-web/html/pages/stories/[slug]-novels.vue").then(m => m.default || m)
  },
  {
    name: "stories",
    path: "/stories",
    component: () => import("/var/www/fictionme-web/html/pages/stories/index.vue").then(m => m.default || m)
  }
]