import * as pkg from "vue-toastification";

export default defineNuxtPlugin((nuxtApp) => {
    const { useToast } = pkg;

    nuxtApp.vueApp.use(pkg.default, {
        position: 'top-center',
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
    });

    return {
        provide: {
            toast: useToast()
        }
    }
})