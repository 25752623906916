
export default defineNuxtPlugin((nuxtApp) => {
    const params = useRoute().query;
    if (params.utm_source) {
        const source = useCookie('_fm_source')
        source.value = params.utm_source as string;
        useState('_fm_source', () => params.utm_source)
    }
    if (params.utm_campaign) {
        const campaign = useCookie('_fm_campaign')
        campaign.value = params.utm_campaign as string;
        useState('_fm_campaign', () => params.utm_campaign)
    }
})